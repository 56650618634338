import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { catchError, map, mergeMap, Observable, of, tap, throwError } from "rxjs";
import { JWTToken } from "../business/interfaces/JWTToken";
import { APIErrorHandler } from "../common/classes/APIErrorHandler";
import {AppNavigator, NavigatorTarget} from "./app-navigator";
import { UserDatabase } from "../business/database/user-database.service";
import { environment } from "../../environments/environment";
import { TokenStorage } from "../common/local-storage/TokenStorage";
import { APIType } from "../common/enums/APIType";
import { APIURLExtension } from "../common/classes/APIURLExtension";
import { ResponseStatusCode } from "../common/enums/ResponseStatusCode";
import { AuthorizationType } from "../common/enums/AuthorizationType";
import { RequestType } from "../common/enums/RequestType";
import { ValidationErrorType } from "../common/enums/ValidationErrorType";


@Injectable({
  providedIn: "root",
})
export class APIClientService {
  baseUrl = environment.apiUrl;
  apiVersion = "v1";

  private jwtToken: JWTToken | undefined;
  private refreshTokenTimer: ReturnType<typeof setTimeout> | undefined;

  constructor(
    private http: HttpClient,
    private errorHandler: APIErrorHandler,
    private httpClient: HttpClient,
    private tokenStorage: TokenStorage,
    private UserDatabase: UserDatabase,
    private appNavigator: AppNavigator
  ) {
    this.baseUrl = environment.apiUrl;
  }

  call<REQUEST, RESPONSE>(parameters: {
    apiType: APIType;
    requestType: RequestType;
    authorizationType?: AuthorizationType;
    pathVariables?: string;
    body?: REQUEST | null;
    getParameters?: string;
    dataUrl?: string;
    listDataUrl?: string[];
  }): Observable<RESPONSE> {
    if (parameters.authorizationType === undefined)
      parameters.authorizationType = AuthorizationType.ACCESS_TOKEN;
    if (parameters.pathVariables === undefined) parameters.pathVariables = "";
    if (parameters.dataUrl === undefined) parameters.pathVariables = "";
    if (parameters.body === undefined) parameters.body = null;
    if (parameters.listDataUrl === undefined) parameters.listDataUrl = [];

    let url = APIURLExtension.getURL(parameters.apiType);

    if(parameters.listDataUrl.isNotEmpty()){
      url = url.format(...parameters.listDataUrl)
    } else {
      url = url.replace("{}", parameters.dataUrl ?? "");
    }

    let request = this.getDefaultParameters(parameters.body);
    console.log("Api request ===> " + url + " " + JSON.stringify(request));
    let response: Observable<HttpResponse<RESPONSE>>;

    if (
      parameters.requestType === RequestType.POST ||
      parameters.requestType === RequestType.DELETE ||
      parameters.requestType === RequestType.UPDATE
    ) {

      response = this.getRequestHeaders({
        authorizationType: parameters.authorizationType,
        isPostRequest: true,
      }).pipe(
        mergeMap((headers) => {
          if (parameters.requestType === RequestType.POST) {
            return this.httpClient.post<RESPONSE>(url!, request, {
              observe: "response",
              headers: headers!,
              context: undefined,
            });
          }else if (parameters.requestType === RequestType.UPDATE) {
            return this.httpClient.put<RESPONSE>(url!, request, { // Modified this line
              observe: "response",
              headers: headers!,
              context: undefined,
            });
          } else if (parameters.requestType === RequestType.DELETE) {
            return this.httpClient.delete<RESPONSE>(url!, {
              observe: "response",
              headers: headers!,
              context: undefined,
            });
          } else {
            throw "";
          }
        })
      );
    } else {
      const queryParameters = new URLSearchParams(request).toString();
      let urlWithParameters = `${url}?${queryParameters}`;
      response = this.getRequestHeaders({
        authorizationType: parameters.authorizationType,
        isPostRequest: false,
      }).pipe(
        mergeMap((headers) =>
          this.httpClient.get<RESPONSE>(urlWithParameters, {
            observe: "response",
            headers: headers,
            context: undefined,
          })
        )
      );
    }

    return response.pipe(
      map((response) => {
        return this.handleResponse(response);
      }),
      catchError(this.handleError<RESPONSE>(parameters))
    );
  }

  private getRequestHeaders(parameters: {
    authorizationType: AuthorizationType;
    isPostRequest: boolean;
  }): Observable<HttpHeaders> {

    return this.getAuthorizationToken(parameters.authorizationType).pipe(
      map((token) => {
        let headers: HttpHeaders;

        if (token !== null ) {
          headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
          });
        } else {
          headers = new HttpHeaders({});
        }

        if (parameters.isPostRequest) {
          headers.set("Content-Type", "application/json");
        }

        return headers;
      })
    );
  }

  private getDefaultParameters(body: any): any {
    let modified = {
      ...body,
      lang: "en",
    };
    return modified;
  }

  private getAuthorizationToken(
    authorizationType: AuthorizationType
  ): Observable<string | null> {
    if (authorizationType == AuthorizationType.ACCESS_TOKEN) {
      const token = this.tokenStorage.getAccessToken();
      if(token?.hasActualValue()){
        let isTokenExpired = this.isAccessTokenExpired(token!);
        if(isTokenExpired){
          this.logOut()
          throw "Session Expired"
        }else {
          return of(token);
        }
      }
      return of(null);
    }  else {
      return of(null);
    }
  }

  private handleResponse<RESPONSE>(response: HttpResponse<RESPONSE>): RESPONSE {
    let statusCode = response.status;
    if (
      statusCode == ResponseStatusCode.OK ||
      statusCode == ResponseStatusCode.CREATED ||
      statusCode == ResponseStatusCode.ACCEPTED
    ) {
      if (response.body != null) {
        console.log("Api response ===> " + response.url, response.body);
        return response.body;
      } else return Object();
    } else {
      throw "unKown Error";
    }
  }

  private handleError<RESPONSE>(parameters: {
    apiType: APIType;
    authorizationType?: AuthorizationType;
    contentType?: string;
    pathVariables?: string;
    body?: any;
  }): (err: any, caught: Observable<RESPONSE>) => Observable<any> {
    return (error, _) => {
      console.log(error.error);
      let statusCode = error.status;
      if (
        statusCode == ResponseStatusCode.OK ||
        statusCode == ResponseStatusCode.CREATED ||
        statusCode == ResponseStatusCode.ACCEPTED
      ) {
        let body = error.error.text;
        return of(body);
      } else {
        const processedError = this.errorHandler.handleApiError(error);
        // Rethrow the error for the component to handle
        return throwError(() => processedError);
      }
    };
  }

  adminLogin(username: string, password: string) {
    this.clearJWTToken();

    console.log(
      "Logging in with username " + username + " and password " + password
    );
    return this.http
      .post<JWTToken>(this.baseUrl + "/" + this.apiVersion + "/admin/login", {
        email: username,
        password: password,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          // return throwError(() => new Error('Something went wrong. Please try again later.'))
          // Process the error using ErrorHandlerService
          console.log(error.error);
          const processedError = this.errorHandler.handleApiError(error);
          // Rethrow the error for the component to handle
          return throwError(() => processedError);
          // return throwError(() => new Error('Something bad happened; please try again later.'));
          // return throwError(processedError);
        }),
        map((response: JWTToken) => {
          this.saveJWTToken(response);
          return response;
        })
      );
  }

refreshToken(): Observable<void> {

  return  this.call<any, JWTToken>({
         apiType: APIType.REFRESH_TOKEN,
         requestType: RequestType.POST,
  }).pipe(
    tap(response => {
      console.log(response);
    }),
    mergeMap((response: JWTToken) => {
      this.saveJWTToken(response);
      return of(undefined)
    }),
    catchError(error => {
      console.log(error);
      if (error == ValidationErrorType.UNAUTHORIZED) {
        console.log("Unauthorized response.");
        this.logOut()
      } else if (error.status === 422) {
        console.log("Validation error..");
      }

      return throwError(() => error)
    })
  )
  }

  private saveJWTToken(jwtToken: JWTToken) {
    console.log("Saving token.. First clear existing JWT token if it exists.");
    this.clearJWTToken();
    this.UserDatabase.saveJWTToken({jWTToken: jwtToken});
    this.jwtToken = jwtToken;
    clearTimeout(this.refreshTokenTimer); // Not sure if this is needed.
    let timerTickIn = this.jwtToken.expiresIn * 0.9;
    console.log("refreshing token in " + timerTickIn);
    this.refreshTokenTimer = setTimeout(() => {
      console.log("refreshing token now");
      this.refreshToken().subscribe();
    }, timerTickIn * 1000);
  }

  private clearJWTToken() {
    console.log("clearing token now");
    clearTimeout(this.refreshTokenTimer);

    this.jwtToken = undefined;

    // localStorage.removeItem("token")
    // localStorage.removeItem("tokenExpiry")
    // localStorage.removeItem("tokenType")
    // localStorage.removeItem("user")

    localStorage.clear(); // TEST

    // TODO remove mobile number from local storage
  }

  private isLoggedIn() {
    if (this.jwtToken) {
      if (!localStorage.getItem("token")) {
        localStorage.setItem("token", this.jwtToken.accessToken);
      }

      if (!localStorage.getItem("tokenExpiry")) {
        localStorage.setItem(
          "tokenExpiry",
          Math.floor(new Date().getTime() / 1000) + this.jwtToken.accessToken
        );
      }

      if (!localStorage.getItem("tokenType")) {
        localStorage.setItem("tokenType", this.jwtToken.tokenType);
      }

      if (!localStorage.getItem("user")) {
        if (this.jwtToken.user != null)
          localStorage.setItem("user", JSON.stringify(this.jwtToken.user));
        else localStorage.removeItem("user");
      }
      return true;
    }
    return false;
  }

  private getToken() {
    if (this.jwtToken == undefined) {
      if (
        localStorage.getItem("token") &&
        localStorage.getItem("tokenType") &&
        localStorage.getItem("tokenExpiry") &&
        localStorage.getItem("user")
      ) {
        this.jwtToken = {
          accessToken: localStorage.getItem("token")!,
          tokenType: localStorage.getItem("tokenType")!,
          expiresIn: parseInt(localStorage.getItem("tokenExpiry")!),
          user: JSON.parse(localStorage.getItem("user")!),
        };
      }

      // localStorage.setItem("tokenExpiry", Math.floor(new Date().getTime() / 1000)  + this.jwtToken.accessToken)
      // localStorage.setItem("tokenType", this.jwtToken.tokenType)
      // if(this.jwtToken != null && this.jwtToken.user != null)
      //   localStorage.setItem("user", JSON.stringify(this.jwtToken.user));
      // else
      //   localStorage.removeItem("user")
    }
  }

   isAccessTokenExpired(accessToken: string): boolean {
    try {
      const tokenParts = accessToken.split('.');
      const tokenPayload = JSON.parse(atob(tokenParts[1])); // Decode the token payload

      const expirationTimestamp: number = tokenPayload.exp; // Extract the expiration claim

      if (!expirationTimestamp) {
        return true; // Token doesn't have an expiration claim, consider it expired
      }

      const currentTimestamp: number = Math.floor(Date.now() / 1000); // Get current timestamp in seconds

      return currentTimestamp >= expirationTimestamp; // Compare current timestamp with expiration claim
    } catch (error) {
      return true; // Error occurred while decoding the token or accessing the expiration claim, consider it expired
    }
  }


  private logOut(){
    this.UserDatabase.removeCurrentUser();
    this.appNavigator.navigateTo({target: NavigatorTarget.login});
   }


  updateUserAuthentication() {
    const token = this.tokenStorage.getAccessToken();
    if(token?.hasActualValue()){
      let isTokenExpired = this.isAccessTokenExpired(token!);
      if(isTokenExpired){
        this.logOut()
      }else {
        this.refreshToken().subscribe()
      };
    }else{
      this.logOut()
    }
  }

}
