import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { Merchant } from "../../../../business/interfaces/Merchant";
import { ActivatedRoute } from "@angular/router";
import { AppNavigator } from "../../../../services/app-navigator";
import { MerchantAPI } from "../../../../business/apis/MerchantAPI";
import { FileUpload } from "../../../../business/interfaces/FileUpload";
import { Image } from "../../../../business/interfaces/Image";
import { generateFilePaths } from "../../../../common/utilities/General";
import { MediaType } from "../../../../business/enums/MediaType";

@Component({
  selector: 'merchant-images',
  templateUrl: './merchant-images.component.html',
  styleUrls: ['./merchant-images.component.css']
})
export class MerchantImagesComponent {
  @Input() loadData: BehaviorSubject<any> | null = null;
  @Output() onchange: EventEmitter<any> = new EventEmitter<any>()

  merchantDetails: Merchant | null = null;
  loadDataImageSource?: BehaviorSubject<any>;
  public currentNumberOfImages: number = 0
  public imagesFilePath: string = ""

  constructor(
    private activatedRoute: ActivatedRoute,
    private appNavigator: AppNavigator,
    private merchantApi: MerchantAPI
  ) {
    this.loadDataImageSource = new BehaviorSubject<any>(null);
  }

  get imageURLs() {
    return '';
  }

  ngOnInit(): void {
    this.loadData!.subscribe((data) => {
      this.merchantDetails = data;
      let images = this.merchantDetails?.images ?? [];
      let files = this.mapToFileUpLoadList(images);
      this.loadDataImageSource?.next(files)
      let filePaths = generateFilePaths({
        merchantId: this.merchantDetails?.id ?? "",
      });

      this.imagesFilePath = filePaths.imagesFilePath ?? "";

    });
  }

  ngOnDestroy(): void {
    this.loadData?.unsubscribe();
    this.loadDataImageSource?.unsubscribe()
  }

  ngAfterViewInit(): void {

  }

  updateViews(value: any) {
    this.onchange?.emit(value)
  }

  onImageUploaded(file: any) {
    let fileUploaded = file as FileUpload;
    let url = fileUploaded.remoteURL;
    if (url.hasActualValue()) {
      let merchantId = this.merchantDetails?.id ?? ""

      this.merchantApi.addImage({
        merchantId: merchantId,
        type: MediaType.IMAGE,
        url: url
      }).subscribe({
        next: (item) => {
          if (item.hasValue()) {
            fileUploaded.id = item;
          }
        },
        error: (_: any) => {
        },
      });
    }
  }

  onImageRemoved(file: FileUpload) {
    this.merchantApi.deleteImage({
      imageId: file.id,
      merchantId: this.merchantDetails?.id ?? ""
    }).subscribe({
      next: (item) => {
        console.log(item);
      },
      error: (_: any) => {
      },
    });
  }

  updateCurrentNumberOfImages(numberOfImages: number) {
    this.currentNumberOfImages = numberOfImages
  }

  mapToFileUpLoadList(list: Image[]): FileUpload[] {
    const valueReturn: FileUpload[] = list
      .filter(item => item.type === MediaType.IMAGE)
      .map(item => this.mapToFileUpload(item));
    return valueReturn;
  }

  mapToFileUpload(item: Image): FileUpload {
    let file = new FileUpload(null);
    file.uploading = false
    file.id = item.id
    file.remoteURL = item.url
    file.localURL = item.url
    return file;
  }
}
