import { Injectable } from "@angular/core";
import { APIClientService } from "../../services/apiclient.service";
import { catchError, map, Observable, tap, throwError } from "rxjs";
import { Product } from "../interfaces/Product";
import { APIType } from "../../common/enums/APIType";
import { ListProductResponse } from "../interfaces/response-pagination/ListProductResponse";
import { ProductFilterData } from "../classes/ProductFilterData";
import { RequestType } from "../../common/enums/RequestType";
import { MediaType } from "../enums/MediaType";

@Injectable({ providedIn: 'root' })

export class ProductAPI {
  constructor(private readonly apiClient: APIClientService) {

  }


  save(parameters: {
    product: Product,
    merchantId: string
  }): Observable<string> {

    let product = parameters.product;
    let productId = product.id ?? "";
    let dataUrl = [parameters.merchantId];
    let isUpdated = false;
    if (productId.hasActualValue()) {
      isUpdated = true
      dataUrl = [productId];
    }

    let request = {
      nameEn: product.nameEn,
      nameAr: product.nameAr,
      descriptionEn: product.descriptionEn,
      descriptionAr: product.descriptionAr,
      categoryId: product.categoryId,
      state: product.state,
      readyWithin: product.readyWithin,
      currency: product.currency,
      price: product.price,
      thumbnailURL: product.thumbnailURL,
    }


    return this.apiClient.call<any, string>({
      apiType: isUpdated ? APIType.UPDATE_PRODUCT : APIType.ADD_MERCHANT_PRODUCT,
      requestType: RequestType.POST,
      body: request,
      listDataUrl: dataUrl,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }

  getListForMerchant(parameters: {
    filterData: ProductFilterData,
    merchantId: string,
  }): Observable<ListProductResponse> {
    let filterData = parameters.filterData
    let parameter = ProductFilterData.getParameterApi(filterData);

    let dataUrl = [parameters.merchantId]
    return this.apiClient.call<any, ListProductResponse>({
      apiType: APIType.Get_MERCHANT_PRODUCTS,
      requestType: RequestType.GET,
      listDataUrl: dataUrl,
      body: parameter,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }




  getList(filterData: ProductFilterData): Observable<ListProductResponse> {
    let parameter = ProductFilterData.getParameterApi(filterData);
    return this.apiClient.call<any, ListProductResponse>({
      apiType: APIType.GET_ALL_PRODUCT,
      body: parameter,
      requestType: RequestType.GET,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }


  getDetails(parameters: {
    productId: string,
  }): Observable<Product> {
    let dataUrl = parameters.productId;
    return this.apiClient.call<any, Product>({
      apiType: APIType.GET_PRODUCT_DETAILS,
      requestType: RequestType.GET,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  delete(parameters: {
    product: Product,
  }): Observable<void> {
    let dataUrl = parameters.product.id
    return this.apiClient.call<any, void>({
      apiType: APIType.DELETE_PRODUCT,
      requestType: RequestType.DELETE,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  addImage(parameters: {
    productId: string,
    type: MediaType,
    url: string
  }): Observable<string> {

    let productId = parameters.productId;
    let dataUrl = [productId];
    let request = {
      url: parameters.url,
      type: parameters.type,
    }

    return this.apiClient.call<any, string>({
      apiType: APIType.ADD_PRODUCT_IMAGE,
      requestType: RequestType.POST,
      body: request,
      listDataUrl: dataUrl,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  deleteImage(parameters: {
    imageId: string,
  }): Observable<void> {
    let dataUrl = [parameters.imageId]
    return this.apiClient.call<any, void>({
      apiType: APIType.DELETE_PRODUCT_IMAGE,
      requestType: RequestType.DELETE,
      listDataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }

}
