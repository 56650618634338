export enum APIType {
  REFRESH_TOKEN,
  adminLogin,
  adminRegister,

  addMerchant,
  getAllMerchant,
  getMerchantDetails,
  deleteMerchant,
  approveMerchant,
  rejectMerchant,
  blockMerchant,
  unblockMerchant,
  ADD_MERCHANT_AREA,
  DELETE_MERCHANT_AREA,
  ADD_MERCHANT_ADDRESS,
  DELETE_MERCHANT_ADDRESS,
  ADD_MERCHANT_PHONE,
  DELETE_MERCHANT_PHONE,
  ADD_MERCHANT_WEBSITE,
  DELETE_MERCHANT_WEBSITE,
  ADD_MERCHANT_SOCIAL_MEDIA_PLATFORM,
  DELETE_MERCHANT_SOCIAL_MEDIA_PLATFORM,
  ADD_MERCHANT_GEOLOCATION,
  DELETE_MERCHANT_GEOLOCATION,
  ADD_MERCHANT_PRODUCT,
  Get_MERCHANT_PRODUCTS,
  Get_MERCHANT_Users,
  ADD_MERCHANT_IMAGE,
  DELETE_MERCHANT_IMAGE,

  ADD_MENU_ITEM,
  DELETE_MENU_ITEM,


  ADD_CATEGORY,
  GET_LIST_CATEGORIES,
  GET_CATEGORY_DETAILS,
  DELETE_CATEGORY,

  ADD_CITY,
  GET_LIST_CITIES,
  GET_CITY_DETAILS,
  DELETE_CITY,

  ADD_COUNTRY,
  GET_LIST_COUNTRIES,
  GET_COUNTRY_DETAILS,
  DELETE_COUNTRY,

  ADD_CURRENCY,
  GET_LIST_CURRENCIES,
  GET_CURRENCY_DETAILS,
  DELETE_CURRENCY,


  ADD_AREA,
  GET_LIST_AREAS,
  GET_AREA_DETAILS,
  DELETE_AREA,


  GET_ALL_PRODUCT,
  GET_PRODUCT_DETAILS,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,

  ADD_PRODUCT_IMAGE,
  DELETE_PRODUCT_IMAGE,


  ADD_USER,
  GET_ALL_USER,
  GET_USER_DETAILS,
  DELETE_USER,
  BLOCK_USER,
  UNBLOCK_USER,

  GET_ALL_CUSTOMER_SUBSCRIPTION,


  GET_ALL_MERCHANT_ClAIM,
  APPROVE_MERCHANT_Claim,
  REJECT_MERCHANT_Claim,

  GET_FIREBASE_TOKEN,

  GET_ACCOUNT_INFO,
  UPDATE_ACCOUNT_INFO,
  UPDATE_PASSWORD,


  GET_ALL_MERCHANT_RATE,
  GET_MERCHANT_RATE,

  GET_ALL_PRODUCT_RATE,
  GET_PRODUCT_RATE,

  APPROVE_RATE,
  REJECT_RATE,
  DELETE_RATE,

  GET_RATE_DETAILS,

  ADD_REPLAY_RATE,
  DELETE_REPLAY_RATE,
}



