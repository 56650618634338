import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MerchantAPI } from "../../../../business/apis/MerchantAPI";
import { ErrorHandling } from "../../../../common/classes/ErrorHandling";
import { ActivatedRoute } from "@angular/router";
import { InputField } from "../../../interfaces/InputField";
import { Merchant, MerchantNameId } from "../../../../business/interfaces/Merchant";
import { InputType } from "../../../../components/input/enum/InputType";
import { InputFieldType } from "../../../../components/input/enum/InputFieldType";
import { FormGroup, Validators } from "@angular/forms";
import { isFormSubmitted } from "../../../../common/utilities/FormsHelper";
import { AppNavigator, NavigatorTarget } from "../../../../services/app-navigator";
import {generateFilePaths} from "../../../../common/utilities/General";
import { SlugValidator } from "../../../../components/input/validators/slug-validator";
import { UrlValidator } from "../../../../components/input/validators/url-validator";

@Component({
  selector: "merchant-basic-info",
  templateUrl: "./merchant-basic-info.component.html",
  styleUrls: ["./merchant-basic-info.component.css"],
})
export class MerchantBasicInfoComponent {
  @Input() loadData: BehaviorSubject<any> | null = null;
  @Output() reloadData = new EventEmitter<string>();

  merchantDetails: Merchant | null = null;
  merchantFields: InputField[] = [];
  form: FormGroup = new FormGroup({});
  errorHandling: ErrorHandling | null = null;
  isLoading = false;

  nameEnglishItem!: InputField;
  nameArabicItem!: InputField;
  briefEnglishItem!: InputField;
  briefArabicItem!: InputField;
  taxIdentificationNumberItem!: InputField;
  deliveryAvailableItem!: InputField;
  pickupAvailableItem!: InputField;
  slugItem!: InputField;
  profilePictureItem!: InputField;
  coverPhotoItem!: InputField;

  logoFilePath: string =  "";
  coverPhotoFilePath: string = "";

  canUpdateSlug = false

  constructor(
    private activatedRoute: ActivatedRoute,
    private appNavigator: AppNavigator,
    private merchantApi: MerchantAPI
  ) {}

  ngOnInit(): void {
    this.merchantFields = this.getInputFields();
    this.loadData!.subscribe((data) => {
      this.merchantDetails = data;
      this.reloadDataViews();
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.loadData?.unsubscribe();
  }

  ngAfterViewInit(): void {}

  private getInputFields(): InputField[] {
    this.nameEnglishItem = {
      id: MerchantNameId.nameEn,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Name En",
      validators: [Validators.required],
      value: "",
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.nameArabicItem = {
      id: MerchantNameId.nameAr,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Name Arabic",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.briefEnglishItem = {
      id: MerchantNameId.briefEn,
      inputFieldType: InputFieldType.textArea,
      floatingLabel: "brief En",
      validators: [],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.briefArabicItem = {
      id: MerchantNameId.briefAr,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "brief Arabic",
      validators: [],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.taxIdentificationNumberItem = {
      id: MerchantNameId.taxIdentificationNumber,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Tax Identification Number",
      validators: [],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.deliveryAvailableItem = {
      id: MerchantNameId.deliveryAvailable,
      inputFieldType: InputFieldType.checkBox,
      label: "Delivery Available",
      value: false,
      validators: [],
      loadData: new BehaviorSubject<any>(false),
    } as InputField;

    this.pickupAvailableItem = {
      id: MerchantNameId.pickupAvailable,
      inputFieldType: InputFieldType.checkBox,
      label: "pickup Available",
      value: false,
      validators: [],
      loadData: new BehaviorSubject<any>(false),
    } as InputField;

    this.slugItem = {
      id: MerchantNameId.slug,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Slug",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>(""),
    } as InputField;



    this.profilePictureItem = {
      id: MerchantNameId.profilePictureURL,
      inputFieldType: InputFieldType.image,
      label: "Profile Picture",
      validators: [],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.coverPhotoItem = {
      id: MerchantNameId.coverPhotoURL,
      inputFieldType: InputFieldType.image,
      label: "Cover Photo",
      validators: [],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    return [
      this.nameEnglishItem,
      this.nameArabicItem,
      this.briefEnglishItem,
      this.briefArabicItem,
      this.taxIdentificationNumberItem,
      this.slugItem,
      this.deliveryAvailableItem,
      this.pickupAvailableItem,
      this.profilePictureItem,
      this.coverPhotoItem,
    ];
  }

  getInputFieldById(id: MerchantNameId): InputField | undefined {
    let item = this.merchantFields.find((field) => field.id === id);
    return item;
  }

  updateInputFieldById(id: string, value: any) {
    let fieldId = (MerchantNameId as any)[id];
    let field = this.getInputFieldById(fieldId);
    if (field != undefined) {
      field.value = value;
    }
  }

  getValueFieldById(id: string): Object {
    let fieldId = (MerchantNameId as any)[id];
    let field = this.getInputFieldById(fieldId);
    return field?.value ?? null;
  }

  submit() {
    if (isFormSubmitted(this.form)) {
      this.updateMerchant();
    }
  }

  updateMerchant() {
    let merchant = {
      id: this.merchantDetails?.id ?? "",
      nameEn:
        this.getValueFieldById(MerchantNameId.nameEn) as string ?? "",
      nameAr:
        this.getValueFieldById(MerchantNameId.nameAr) as string ?? "",
      briefEn:
        this.getValueFieldById(MerchantNameId.briefEn) as string ?? "",
      briefAr:
        this.getValueFieldById(MerchantNameId.briefAr) as string ?? "",
      taxIdentificationNumber:
        this.getValueFieldById(
          MerchantNameId.taxIdentificationNumber
        )?.toStringValue() ?? "",
      slug: this.getValueFieldById(MerchantNameId.slug) as string ?? "",
      deliveryAvailable:
        this.getValueFieldById(
          MerchantNameId.deliveryAvailable
        )?.toBoolNumber() ?? false,
      pickupAvailable:
        this.getValueFieldById(MerchantNameId.pickupAvailable)?.toBoolNumber() ??
        false,
      coverPhotoURL:
        this.getValueFieldById(MerchantNameId.coverPhotoURL) as string ?? "",
      profilePictureURL:
        this.getValueFieldById(
          MerchantNameId.profilePictureURL
        ) as string ?? "",
    } as Merchant;

    console.log(merchant);
    this.restError();
    this.isLoading = true;
    this.merchantApi.save({ merchant: merchant ,oldMerchant:this.merchantDetails }).subscribe({
      next: (item) => {
        console.log(item);
        let merchantId = this.merchantDetails?.id ?? "";
        if (item.hasValue()) {
          this.navigateToDetails(item)
        }else {
          this.reloadData!.emit(merchantId);
        }

      },
      error: (error: any) => {
        this.checkError(error);
        this.isLoading = false;
      },
    });
  }

  navigateToDetails(id:string){
    this.appNavigator.navigateTo({
      target: NavigatorTarget.merchantDetails,
      id: id,
    });
  }

  reloadDataViews() {
    let filePaths = generateFilePaths({
      merchantId: this.merchantDetails?.id ?? "",
    })
    this.logoFilePath = filePaths.logoFilePath;
    this.coverPhotoFilePath = filePaths.coverPhotoFilePath;

    this.getInputFieldById(MerchantNameId.nameEn)?.loadData?.next(
      this.merchantDetails?.nameEn
    );
    this.getInputFieldById(MerchantNameId.nameAr)?.loadData?.next(
      this.merchantDetails?.nameAr
    );
    this.getInputFieldById(MerchantNameId.briefEn)?.loadData?.next(
      this.merchantDetails?.briefEn
    );
    this.getInputFieldById(MerchantNameId.briefAr)?.loadData?.next(
      this.merchantDetails?.briefAr
    );
    this.getInputFieldById(
      MerchantNameId.taxIdentificationNumber
    )?.loadData?.next(this.merchantDetails?.taxIdentificationNumber);
    this.getInputFieldById(MerchantNameId.pickupAvailable)?.loadData?.next(
      this.merchantDetails?.pickupAvailable
    );
    this.getInputFieldById(MerchantNameId.deliveryAvailable)?.loadData?.next(
      this.merchantDetails?.deliveryAvailable
    );
    this.getInputFieldById(MerchantNameId.coverPhotoURL)?.loadData?.next(
      this.merchantDetails?.coverPhotoURL
    );
    this.getInputFieldById(MerchantNameId.profilePictureURL)?.loadData?.next(
      this.merchantDetails?.profilePictureURL
    );

    this.getInputFieldById(MerchantNameId.slug)?.loadData?.next(
      this.merchantDetails?.slug
    );

    if(this.merchantDetails?.slug)
      this.canUpdateSlug = true
    else
      this.canUpdateSlug = false
  }

  checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  restError() {
    this.errorHandling = null;
  }

  // canEditSlug(): boolean {
  //   let slug = this.merchantDetails?.slug ?? "";
  //   if (slug.hasActualValue() &&
  //     SlugValidator.isValid(slug)) {
  //     return false;
  //   }
  //   return true
  // }
}
